export enum PLUGIN_IDS {
  INTERACTION = "INTERACTION",
  ROUTING = "ROUTING",
  SEARCH = "SEARCH",
  GEOFENCE = "GEOFENCE",
  USER_LOCATION = "USER_LOCATION",
  MARKER = "MARKER",
  LIVEREPORTING = "LIVEREPORTING",
  DEBUG = "DEBUG",
  CLUSTERED_PIN = "CLUSTERED_PIN",
  FLOOR = "FLOOR",
  CATEGORIES = "CATEGORIES",
  DYNAMIC_DATA = "DYNAMIC_DATA",
  REGION = "REGION",
}

export enum EventTypes {
  FEATURE_CLICKED = "FEATURE_CLICKED",
  FEATURE_DESELECTED = "FEATURE_DESELECTED",
  ROUTE_DISPLAYED = "ROUTE_DISPLAYED",
  FLOOR_CHANGED = "FLOOR_CHANGED",
}

export enum SourceIds {
  LM_SOURCE_ID = "lm",
  LM_SOURCE_LAYER_INDOOR_ID = "indoor",
  LM_SOURCE_LAYER_OUTDOOR_ID = "outdoor",
  CLUSTER_SOURCE_ID = "pin",
  MMS_PIN_SOURCE_ID = "pin_mms",
  MMS_PIN_SOURCE_LAYER_ID = "pin_mms",
  SELECTED_FEATURE_SOURCE_ID = "selected_feature",
  LIVE_LOCATION_SOURCE_ID = "pin_user",
  ACTIVE_FLOOR_FEATURE_SOURCE_ID = "active_floor_feature",
  INACTIVE_FLOOR_FEATURE_SOURCE_ID = "inactive_floor_feature",
  ROUTE_ICON_SOURCE = "route-icons",
  ROUTE_LINE_SOURCE = "route-line",
  ROUTE_LINE_OTHER_SOURCE = "route-line-other_floor",
  ROUTE_LINE_ANIMATED_SOURCE = "route-line-animated",
}

export enum FeatureLayers {
  SELECTED_FEATURE_LAYER = "search-highlight_pin",
}

export enum LayerIds {
  SELECTED_FEATURE_LAYER = "search-highlight_pin",
  SEARCH_PIN_LAYER = "search-search_pin",
  SEARCH_CLUSTER_LAYER = "search-search_badge_cluster",
  LIVE_LOCATION_LAYER_ID = "search-pin_user",
  UNDERGROUND_MASK_LAYER = "venue-underground_mask",
  ACTIVE_FLOOR_FEATURE_LAYER = "active_floor_feature_layer",
  INACTIVE_FLOOR_FEATURE_LAYER = "inactive_floor_feature_layer",
  ROUTE_LINE_LAYER = "route-line-current_floor",
  ROUTE_LINE_OTHER_LAYER = "route-line-other_floor",
  ROUTE_ICON_LAYER = "route-icons",
}

export const UNDERGROUND_MASK_FILTER_LAYERS = [
  "outdoor-LM-amenity_building-icon_label",
  "outdoor-LM-landmark_illustration-label_only_opto",
  "transport-LM-ferry_terminal-icon_label",
  "transport-LM-bus_station-coach_station-icon_label",
  "transport-LM-tram_station-icon_label",
  "transport-LM-metro_station-icon_label",
  "transport-LM-rail_station-light_rail_station-icon_label",
  "transport-LM-airport-icon_label",
  "transport-OSM-bus_station-icon_label",
  "transport-OSM-metro_station-icon_label",
  "transport-OSM-rail_station-icon_label",
  "transport-OSM-airport-icon_label",
];

export enum StateType {
  SELECTED = "selected",
  HOVER = "hover",
  HIGHLIGHT = "highlight",
}
