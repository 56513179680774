import { useContext } from "react";
import classNames from "classnames";

import {
  Floors,
  InteractionEventTypes,
} from "../../../../redux/services/config";

import { FloorConfig } from "../../plugins/floor-control";

import { MapContext } from "../../Map";

import styles from "./FloorSelector.module.scss";

export interface FloorSelectorProps {
  dataQA: string;
  floors: Floors;
  youAreHereFloor?: string;
  poiFloorId?: number | null;
  onTouch?: (eventType: InteractionEventTypes.LEVEL_SELECTOR_TOUCH) => void;
  activeFloor: FloorConfig;
}

const FloorSelector = ({
  dataQA,
  floors,
  youAreHereFloor,
  poiFloorId,
  onTouch,
  activeFloor,
}: FloorSelectorProps) => {
  const { controlSize, controlTheme, floorControlInstance } =
    useContext(MapContext);

  const classnames = classNames(
    styles.container,
    styles[controlTheme],
    styles[controlSize],
  );

  const floorListItems = Object.entries(floors).map(([id, floorObj]) => {
    const isActive = floorObj.id === activeFloor.id;
    const isUserOnFloor =
      floorObj.id === (youAreHereFloor && floors[youAreHereFloor].id);
    const isPoiOnFloor = poiFloorId && poiFloorId === floorObj.id;
    const listItemClassNames = classNames(
      styles.listItem,
      styles[controlTheme],
      styles[controlSize],
      {
        [styles.active]: isActive,
        [styles.userOnFloor]: isUserOnFloor,
        [styles.poiOnFloor]: isPoiOnFloor,
      },
    );

    const handleFloorSelect = (
      event: React.MouseEvent<HTMLLIElement>,
      floor: string,
    ) => {
      event.stopPropagation();
      const floorToRender = floors[floor];
      floorControlInstance?.setActiveFloor(floorToRender);
      onTouch && onTouch(InteractionEventTypes.LEVEL_SELECTOR_TOUCH);
    };

    return (
      <li
        key={floorObj.floor}
        data-qa={`floor-${floorObj.short_name}`}
        className={listItemClassNames}
        onClick={(event) => handleFloorSelect(event, id)}
      >
        {floorObj.short_name}
      </li>
    );
  });

  floorListItems.sort(function (a, b) {
    if (Number(a.key) < Number(b.key)) {
      return 1;
    }
    return -1;
  });

  return (
    <div data-qa={dataQA} className={classnames}>
      <div className={styles.label}>Level</div>
      <ul className={styles.floorList}>{floorListItems}</ul>
    </div>
  );
};

export default FloorSelector;
