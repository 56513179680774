import classNames from "classnames";
import QRCode from "react-qr-code";
import { Icon } from "@livingmap/core-ui-v2";

import { Theme } from "../../../../redux/services/config";

import {
  Status,
  useCalculateOperatingHours,
} from "../../../../hooks/useCalculateOpeningHours";

import { OperatingHours, scaleValue } from "../../../../utils";

import styles from "./AssetInformation.module.scss";

export enum FloorChange {
  UP = "up",
  DOWN = "down",
  NONE = "none",
}

interface Props {
  dataQA: string;
  theme: Theme;
  name: string;
  operatingHours: OperatingHours;
  qrCodeLink: string;
  category?: string;
  building?: string;
  floorName?: string;
  className?: string;
  isTemporarilyClosed: boolean;
  onClick: () => void;
  imageSrc?: string;
  floorChange: { direction: FloorChange; amount: number };
  kilometres?: number;
}

const AssetInformation: React.FC<Props> = ({
  dataQA,
  theme,
  name,
  operatingHours,
  qrCodeLink,
  category,
  building,
  floorName,
  className,
  isTemporarilyClosed,
  onClick,
  imageSrc,
  floorChange,
  kilometres,
}) => {
  const { openingHoursStatusCopy } = useCalculateOperatingHours(operatingHours);

  const openingHoursWrappedString = openingHoursStatusCopy?.replace(
    /(Open|Closed)/g,
    "<span>$&</span>",
  );

  const floorChangeString =
    floorChange.amount === 0
      ? "This floor"
      : `${floorChange.amount} ${floorChange.amount === 1 ? `floor` : `floors`} ${floorChange.direction}`;

  const getDistance = () => {
    if (!kilometres) return null;
    // If less than 1km, show meters
    const meters = Math.round(kilometres * 1000);
    if (meters < 1) return "<1 m";
    if (meters < 1000) return `${meters} m`;
    else return `${kilometres.toFixed(1)} km`;
  };

  return (
    <div
      className={classNames(
        styles.container,
        styles[theme.mode || "light"],
        styles[theme.size || "small"],
        className,
        { [styles.noImage]: !imageSrc },
      )}
    >
      <div className={styles.qrCodeContainer}>
        <Icon
          dataQA="qr-code-icon"
          type="ShareDirectionsIcon"
          height={scaleValue(14, theme.size || "medium")}
        />
        <p>Scan the code to view the map on your phone</p>
        <div className={styles.qrCode}>
          <QRCode
            value={qrCodeLink}
            size={scaleValue(72, theme.size || "medium")}
          />
        </div>
      </div>
      <div data-qa={dataQA} className={styles.infoContainer}>
        {imageSrc && (
          <img
            data-qa="asset-background"
            src={imageSrc}
            alt="asset-background"
            className={styles.image}
          />
        )}
        <div className={styles.content}>
          <div className={styles.info}>
            <p className={styles.name}>{name}</p>
            {(category || floorName || building || operatingHours) && (
              <div className={styles.description}>
                {category && <p className={styles.subtitle}>{category}</p>}
                {(floorName || building) && (
                  <p className={styles.address}>
                    {floorName && floorName}
                    {floorName && building && (
                      <span className={styles.separator}>•</span>
                    )}
                    {building && building}
                  </p>
                )}
                {operatingHours && (
                  <p
                    className={styles.openingTimes}
                    dangerouslySetInnerHTML={{
                      __html: isTemporarilyClosed
                        ? Status.TEMP_CLOSED
                        : openingHoursWrappedString || "",
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {(getDistance() || floorChange) && (
            <div className={styles.distanceInfo}>
              {getDistance() && (
                <p className={styles.distance}>{getDistance()}</p>
              )}
              {floorChange && (
                <>
                  <p className={styles.floor}>{floorChangeString}</p>
                  {floorChange.direction !== FloorChange.NONE && (
                    <Icon
                      dataQA="floor-change-icon"
                      type="DownShortArrowIcon"
                      height={scaleValue(12, theme.size || "medium")}
                      className={classNames(
                        styles.icon,
                        styles.icon,
                        {
                          [styles.floorUp]:
                            floorChange.direction === FloorChange.UP,
                        },
                        {
                          [styles.floorDown]:
                            floorChange.direction === FloorChange.DOWN,
                        },
                      )}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <button onClick={onClick} className={styles.closeBtn}>
          <Icon
            dataQA="poi-close-icon"
            type="CloseIcon"
            width={scaleValue(14, theme.size || "medium")}
            height={scaleValue(14, theme.size || "medium")}
          />
        </button>
      </div>
    </div>
  );
};

export default AssetInformation;
