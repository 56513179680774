import classNames from "classnames";
import { useContext } from "react";
import {
  Status,
  useCalculateOperatingHours,
} from "../../hooks/useCalculateOpeningHours";
import { OpeningTimes } from "../../redux/services/config";

import { MapContext } from "../Map/Map";

import styles from "./SearchResult.module.scss";

export interface SearchResultProps {
  category: string | null;
  className?: string;
  floor?: string;
  imageUrl: string | null;
  onClick?: () => void;
  openingTimes: OpeningTimes;
  showImage: boolean;
  title: string;
  titleSearch: boolean;
  isTemporarilyClosed: boolean;
}

export default function SearchResult({
  category,
  className,
  floor,
  imageUrl,
  onClick,
  openingTimes,
  showImage,
  title,
  titleSearch,
  isTemporarilyClosed,
}: SearchResultProps) {
  const { controlTheme, controlSize } = useContext(MapContext);

  const { status, openingHoursStatusCopy } =
    useCalculateOperatingHours(openingTimes);

  return (
    <li
      className={classNames(
        styles.container,
        styles[controlSize],
        styles[controlTheme],
        className,
      )}
      onClick={onClick}
    >
      {showImage && (
        <div className={styles.imageContainer}>
          {imageUrl && (
            <img
              data-qa="search-result-image"
              className={styles.image}
              src={imageUrl}
              alt="search-result"
            />
          )}
        </div>
      )}
      <>
        <div className={styles.textContent}>
          <div
            className={classNames(styles.title, {
              [styles.titleSearch]: titleSearch,
            })}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className={styles.subTextContent}>
            {category && (
              <div className={classNames(styles.text, styles.category)}>
                {category.slice(0, 1).toUpperCase() + category.slice(1)}
              </div>
            )}
            {floor && (
              <div className={classNames(styles.text, styles.floor)}>
                {floor}
              </div>
            )}
          </div>
          {openingTimes && (
            <div
              className={classNames(styles.text, styles.openingTimes, {
                [styles.closed]:
                  status === Status.CLOSED || isTemporarilyClosed,
              })}
            >
              {isTemporarilyClosed
                ? Status.TEMP_CLOSED
                : openingHoursStatusCopy}
            </div>
          )}
        </div>
      </>
    </li>
  );
}
