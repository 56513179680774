import { LMFeature } from "@livingmap/core-mapping";

import { Feature } from "../redux/services/config";

interface EnhancedFeature extends Feature {
  state: {};
}

export const createLMFeatures = (features: Feature[]) => {
  return features.map((feature) => {
    const enhancedFeature = { ...feature } as EnhancedFeature;

    if (!enhancedFeature.state) {
      enhancedFeature.state = {};
    }

    return new LMFeature(enhancedFeature);
  });
};
