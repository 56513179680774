import LivingMap, {
  FilterKing,
  GlobalFilters,
  LivingMapPlugin,
} from "@livingmap/core-mapping";
import {
  all,
  any,
  equals,
  get,
  has,
  not,
} from "@livingmap/core-mapping/dist/filter/expressions";
import { Floors } from "../../../redux/services/config";
import {
  SourceIds,
  LayerIds,
  UNDERGROUND_MASK_FILTER_LAYERS,
} from "./types/index";

export interface FloorConfig {
  name: string;
  id: number;
  default?: boolean;
  active?: boolean;
  floor?: string;
  short_name?: string;
}

export enum FloorDirection {
  UP = "UP",
  DOWN = "DOWN",
}

/**
 * Underground mask is used a programmtic way to shade the background of any indoor building
 * That is underneath the ground floor.
 */
export const FLOOR_CHANGED = "FLOOR_CHANGED";

export default class FloorControl extends LivingMapPlugin {
  private activeFloor: FloorConfig | null = null;
  private floorConfigLoaded = false;
  private filterInstance: FilterKing;
  private timeoutID: ReturnType<typeof setTimeout> | null;
  private groundFloor: FloorConfig | null = null;

  constructor(id: string, LMMap: LivingMap) {
    super(id, LMMap);
    this.filterInstance = LMMap.getFilterKing();
    this.timeoutID = null;
  }

  public activate(): void {
    this.LMMap.getLayerDelegate().trackLayer(LayerIds.UNDERGROUND_MASK_LAYER);
    this.floorConfigLoaded = true;
  }

  public deactivate(): void {
    this.LMMap.getLayerDelegate().removeLayer(LayerIds.UNDERGROUND_MASK_LAYER);
  }

  public refresh(): void {
    if (this.activeFloor) this.setActiveFloor(this.activeFloor);
  }

  public setGroundFloor(floors: Floors) {
    Object.keys(floors).forEach((floorId) => {
      const floorObj = floors[floorId];
      if (floorObj.floor === "0.0") this.groundFloor = floorObj;
    });
  }

  public getGroundFloor() {
    return this.groundFloor;
  }

  public getActiveFloor(): FloorConfig | null {
    return this.activeFloor;
  }

  public setActiveFloor(newFloor: FloorConfig | null): void {
    if (newFloor) {
      this.activeFloor = newFloor;
      this.LMMap.emit(FLOOR_CHANGED, newFloor);
      this.updateMapFloorFilter();
    }
  }

  public isFloorConfigLoaded(): boolean {
    return this.floorConfigLoaded;
  }

  private setUndergroundLayerVisbilityMask = (layer: any, vis: boolean) => {
    if (layer.metadata && layer.metadata.programmatic) return;

    const layerDelegate = this.LMMap.getLayerDelegate();

    const isHideableLayer =
      layer.type === "symbol" &&
      layer["source-layer"] !== SourceIds.LM_SOURCE_LAYER_INDOOR_ID &&
      layer.source !== SourceIds.CLUSTER_SOURCE_ID &&
      layer.source !== SourceIds.SELECTED_FEATURE_SOURCE_ID &&
      layer.source !== SourceIds.MMS_PIN_SOURCE_ID &&
      !UNDERGROUND_MASK_FILTER_LAYERS.includes(layer["id"]);

    if (isHideableLayer) {
      layerDelegate.trackLayer(layer.id);
      layerDelegate.setLayoutProperty(
        layer.id,
        "visibility",
        vis ? "none" : "visible",
      );
    }

    layerDelegate.setLayoutProperty(
      LayerIds.UNDERGROUND_MASK_LAYER,
      "visibility",
      vis ? "visible" : "none",
    );
  };

  // is used to add a "underground mask" layer when the active floor is below the ground floor.
  // this mask will slightly darken most geometries above ground to give a better impression that the current floor indeed is below ground.
  private actOnFloorGroundTypeChange = (newFloorIsAboveGround: boolean) => {
    const mapInstance = this.LMMap.getMapboxMap();

    if (!mapInstance.getLayer(LayerIds.UNDERGROUND_MASK_LAYER)) {
      return;
    }

    const layers = mapInstance.getStyle().layers;
    if (newFloorIsAboveGround)
      layers!.forEach((layer) =>
        this.setUndergroundLayerVisbilityMask(layer, false),
      );
    else
      layers!.forEach((layer) =>
        this.setUndergroundLayerVisbilityMask(layer, true),
      );
  };

  /**
   * Updates the Living Map object with a new map global filter to remove any geometry not assosicated
   * with the active floor.
   *
   * Note: The filtering will not be applied if the configuration conists of only 1 floor!
   * @returns void
   */
  private updateMapFloorFilter(): void {
    if (!this.activeFloor) {
      this.filterInstance.removeGlobalFilterForFilterId(GlobalFilters.FLOOR);
    }

    if (this.timeoutID) clearTimeout(this.timeoutID);

    const floorLevel = parseFloat(this.activeFloor!.floor!);

    // Add a timeout for this function, to ensure updateGlobalFilter has time to complete first
    this.timeoutID = setTimeout(() => {
      if (floorLevel < 0) this.actOnFloorGroundTypeChange(false);
      else this.actOnFloorGroundTypeChange(true);
    }, 150);

    const groundFloor = this.getGroundFloor();

    if (this.activeFloor?.id && groundFloor?.id) {
      this.filterInstance.updateGlobalFilter(
        GlobalFilters.FLOOR,
        any(
          equals(get("floor_id"), this.activeFloor.id),
          equals(get("floor_id"), ""),
          not(has("floor_id")),
          all(
            equals(get("floor_id"), groundFloor.id), // TODO: remove static ground floor in favour on config
            equals(get("indoor"), false),
            not(has("pin_id")),
          ),
        ),
      );
    }
  }
}
