import { createRoot } from "react-dom/client";
import mapboxgl from "mapbox-gl";
import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
  LMFeature,
  StateType,
} from "@livingmap/core-mapping";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import classNames from "classnames";

import FloorControl, { FloorConfig } from "./floor-control";
import RoutingPlugin from "./routing-control";
import { EMPTY_DATA_SOURCE } from "./position-control";
import { LayerIds, SourceIds } from "./types/index";
import {
  getFloorById,
  getControlTheme,
  ControlTheme,
  parseOpeningTimeString,
  buildRouteShortlink,
} from "../../../utils";
import { Floors, InteractionEventTypes } from "../../../redux/services/config";
import AssetInformation, {
  FloorChange,
} from "../components/AssetInformation/AssetInformation";
import AssetLabel from "../components/AssetLabel/AssetLabel";
import { OnTouchHandlerOptions } from "../../../templates/BaseWithHeader/BaseWithHeader";
import { store } from "../../../store";

enum FeatureDotTypes {
  ACTIVE = "active-feature-dot",
  INACTIVE = "inactive-feature-dot",
}

interface PopupStore {
  [key: string]: mapboxgl.Popup;
}

interface ActiveMapboxFeatureStore {
  [key: string]: Feature<Geometry, GeoJsonProperties>;
}

class ClusteredPinPlugin extends LivingMapPlugin {
  private alternateShortlink: string | undefined;
  private features: LMFeature[] = [];
  private floorPlugin: FloorControl;
  private routingPlugin: RoutingPlugin;
  private mapInstance?: mapboxgl.Map;
  private layerDelegate: LayerDelegate;
  private popupStore: PopupStore = {};
  private activeMapboxFeatureStore: ActiveMapboxFeatureStore = {};
  private controlTheme: ControlTheme | null;
  private floors: Floors;
  private onTouch:
    | ((
        eventType: InteractionEventTypes,
        options?: OnTouchHandlerOptions,
      ) => void)
    | undefined;
  private onFeatureSelect: ((feature: Feature | null) => void) | undefined;
  private userLngLat: [number, number];
  public selectedFeature: Feature | null = null;
  private defaultFloor: FloorConfig;

  public constructor(
    id: string,
    LMMap: LivingMap,
    floorPlugin: FloorControl,
    routingPlugin: RoutingPlugin,
    floors: Floors,
    userLngLat: [number, number],
    defaultFloor: FloorConfig,
    onTouch?: (eventType: InteractionEventTypes) => void,
    onFeatureSelect?: (feature: Feature | null) => void,
  ) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.floorPlugin = floorPlugin;
    this.routingPlugin = routingPlugin;
    this.layerDelegate = LMMap.getLayerDelegate();
    this.controlTheme = getControlTheme();
    this.floors = floors;
    this.onTouch = onTouch;
    this.onFeatureSelect = onFeatureSelect;
    this.alternateShortlink = process.env.REACT_APP_SHORTLINK_URL;
    this.selectedFeature = null;
    this.userLngLat = userLngLat;
    this.defaultFloor = defaultFloor;
  }

  activate(): void {
    this.mapInstance = this.LMMap.getMapboxMap();
    return;
  }

  public updateFeatureLabels(
    features: LMFeature[],
    updateActiveFloor?: boolean,
  ): void {
    this.routingPlugin.clear();

    this.features = features;
    this.clearFeatureLabels(false);

    if (
      !this.mapInstance?.hasImage(FeatureDotTypes.ACTIVE) &&
      !this.mapInstance?.hasImage(FeatureDotTypes.INACTIVE)
    ) {
      this.mapInstance?.addImage(
        FeatureDotTypes.ACTIVE,
        this.createFeatureDot(FeatureDotTypes.ACTIVE),
      );
      this.mapInstance?.addImage(
        FeatureDotTypes.INACTIVE,
        this.createFeatureDot(FeatureDotTypes.INACTIVE),
      );
    }

    this.layerDelegate.addSource(SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    this.layerDelegate.addSource(SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    this.layerDelegate.addLayer({
      id: LayerIds.ACTIVE_FLOOR_FEATURE_LAYER,
      type: "symbol",
      source: SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID,
      layout: {
        "icon-image": FeatureDotTypes.ACTIVE,
      },
    });

    this.layerDelegate.addLayer({
      id: LayerIds.INACTIVE_FLOOR_FEATURE_LAYER,
      type: "symbol",
      source: SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID,
      layout: {
        "icon-image": FeatureDotTypes.INACTIVE,
      },
    });

    // Update the active floor if there's only a single feature present
    if (this.features.length === 1 && updateActiveFloor) {
      const featureFloorId = this.features[0]?.getFloorId();
      const featureFloor = featureFloorId && getFloorById(featureFloorId);
      if (featureFloor) this.floorPlugin.setActiveFloor(featureFloor);
    }

    this.renderActiveAndInactiveFeatures();
  }

  public reloadFeatureLabels(): void {
    this.updateFeatureLabels(this.features);
  }

  public clearFeatureLabels(deleteFeatures: boolean = true): void {
    this.layerDelegate.removeLayer(LayerIds.ACTIVE_FLOOR_FEATURE_LAYER);
    this.layerDelegate.removeLayer(LayerIds.INACTIVE_FLOOR_FEATURE_LAYER);
    this.layerDelegate.removeSource(SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID);
    this.layerDelegate.removeSource(SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID);

    for (const key of Object.keys(this.popupStore)) {
      this.popupStore[key].remove();
    }

    this.popupStore = {};

    if (deleteFeatures) {
      this.features = [];
    }
  }

  public clearSelectedFeature(): void {
    if (this.selectedFeature?.properties) {
      this.features.length > 1
        ? this.renderAssetLabel(
            this.selectedFeature.properties.lm_id,
            this.selectedFeature,
          )
        : this.deleteFeature(this.selectedFeature.properties.lm_id);

      this.selectedFeature = null;
    }
  }

  private clearActiveMapboxFeatureStore(): void {
    this.activeMapboxFeatureStore = {};
  }

  private renderActiveAndInactiveFeatures() {
    const currentFloor = this.floorPlugin?.getActiveFloor();
    const hasFloorData = Boolean(currentFloor);

    const featuresOnActiveFloor: LMFeature[] = [];
    const featuresOnInactiveFloors: LMFeature[] = [];

    this.clearActiveMapboxFeatureStore();

    if (this.features.length === 1) {
      featuresOnActiveFloor.push(this.features[0]);
    } else {
      for (const feature of this.features) {
        if (!hasFloorData) {
          featuresOnActiveFloor.push(feature);
          continue;
        }

        const featureFloorId = feature.getFloorId();

        if (
          (this.selectedFeature &&
            featureFloorId === this.selectedFeature.properties?.poi_floor_id) ||
          (!this.selectedFeature && featureFloorId === currentFloor?.id)
        ) {
          featuresOnActiveFloor.push(feature);
        } else {
          featuresOnInactiveFloors.push(feature);
        }
      }
    }

    const activeMapboxFeatures: Feature[] = this.enhanceLMFeatures(
      featuresOnActiveFloor,
    );
    const inactiveMapboxFeatures: Feature[] = this.enhanceLMFeatures(
      featuresOnInactiveFloors,
    );

    for (const mapboxFeature of activeMapboxFeatures.concat(
      inactiveMapboxFeatures,
    )) {
      if (!("coordinates" in mapboxFeature.geometry)) continue;

      const lmID = mapboxFeature?.properties?.lm_id;

      if (!lmID) return;

      if (
        this.features.length === 1 ||
        this.selectedFeature?.properties?.lm_id === lmID
      ) {
        this.renderExpandedAssetInformation(
          lmID,
          mapboxFeature,
          this.features.length === 1,
        );
      } else {
        this.activeMapboxFeatureStore[lmID] = mapboxFeature;
        this.renderAssetLabel(lmID, mapboxFeature);
      }
    }

    const activeFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );
    const inactiveFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );

    activeFloorSourceProxy?.setData({
      type: "FeatureCollection",
      features: activeMapboxFeatures,
    });

    inactiveFloorSourceProxy?.setData({
      type: "FeatureCollection",
      features: inactiveMapboxFeatures,
    });
  }

  private renderExpandedAssetInformation(
    id: string,
    feature: Feature,
    deleteOnClose: boolean,
  ) {
    if (!("coordinates" in feature.geometry)) return;

    const {
      application: { onlineMode, isStepFreeRoute },
    } = store.getState();

    this.selectedFeature = feature;

    this.onFeatureSelect && this.onFeatureSelect(feature);

    // This is done after rendering the route instead if we're in online mode so we skip this step
    if (!onlineMode && this.onTouch)
      this.onTouch(InteractionEventTypes.ASSET_DIALOG_OPEN, {
        featureID: feature.id,
      });

    const container = document.createElement("div");
    const root = createRoot(container);

    const handleOnClick = () => {
      this.routingPlugin.clear();
      this.onTouch && this.onTouch(InteractionEventTypes.ASSET_DIALOG_CLOSE);

      deleteOnClose
        ? this.deleteFeature(id)
        : this.renderAssetLabel(id, feature);
      this.onFeatureSelect && this.onFeatureSelect(null);
    };

    /**
     * The backend API converts the operating hours string for a feature into an object, but if we click on a feature on the map that's not a search result we have the raw operating hours string, so we need to do this conversion in the FE when necessary.
     * */
    const operatingHours = feature.properties?.opening_times || null;
    const codeLink = buildRouteShortlink(feature.id, isStepFreeRoute);
    const formattedOperatingHours =
      typeof operatingHours === "string" || operatingHours === null
        ? parseOpeningTimeString(operatingHours)
        : operatingHours;
    const { floorChangeAmount, floorChangeDirection } = this.getFloorDifference(
      feature.properties?.poi_floor_id,
    );

    root.render(
      <AssetInformation
        dataQA="asset-information"
        name={feature.properties?.popup_header}
        category={feature.properties?.popup_subheader}
        building={feature.properties?.location_name}
        operatingHours={formattedOperatingHours}
        theme={{
          mode: this.controlTheme?.mode,
          size: this.controlTheme?.size,
        }}
        qrCodeLink={codeLink}
        floorName={this.getFloorName(feature)}
        onClick={handleOnClick}
        isTemporarilyClosed={feature.properties?.temporarily_closed}
        imageSrc={feature.properties?.popup_image_url}
        floorChange={{
          direction: floorChangeDirection,
          amount: floorChangeAmount,
        }}
      />,
    );

    for (const key in this.activeMapboxFeatureStore) {
      this.renderAssetLabel(key, this.activeMapboxFeatureStore[key]);
    }

    this.popupStore[id]?.remove();

    const bearingFromUserLngLat = this.calculateBearing(
      this.userLngLat,
      feature.geometry.coordinates as [number, number],
    );

    this.popupStore[id] = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      offset: {
        "bottom-left": [10, -10],
        "bottom-right": [-10, -10],
      },
      anchor: bearingFromUserLngLat < 180 ? "bottom-left" : "bottom-right",
      className: classNames(
        this.controlTheme?.mode,
        "expanded",
        "asset-information",
      ),
    })
      .setLngLat(feature.geometry.coordinates as [number, number])
      .setDOMContent(container)
      .addTo(this.mapInstance!);
  }

  private renderAssetLabel(id: string, feature: Feature) {
    if (!("coordinates" in feature.geometry)) return;

    const floorName = this.getFloorName(feature);

    const container = document.createElement("div");
    const root = createRoot(container);

    const bearingFromUserLngLat = this.calculateBearing(
      this.userLngLat,
      feature.geometry.coordinates as [number, number],
    );

    root.render(
      <AssetLabel
        dataQA="asset-label"
        theme={{
          mode: this.controlTheme?.mode,
          size: this.controlTheme?.size,
        }}
        name={feature.properties?.popup_header}
        floorName={floorName}
        onClick={() => {
          this.renderExpandedAssetInformation(id, feature, false);
          this.reloadFeatureLabels();
        }}
      />,
    );

    this.popupStore[id]?.remove();

    this.popupStore[id] = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      offset: {
        "bottom-left": [10, -10],
        "bottom-right": [-10, -10],
      },
      anchor: bearingFromUserLngLat < 180 ? "bottom-left" : "bottom-right",
      className: this.controlTheme?.mode,
    })
      .setLngLat(feature.geometry.coordinates as [number, number])
      .setDOMContent(container)
      .addTo(this.mapInstance!);
  }

  private getFloorName(feature: Feature) {
    const showFloorDetail = Object.keys(this.floors).length > 1;

    let floorName: string | undefined;

    if (showFloorDetail) {
      floorName = feature.properties?.floor_name;
    }

    return floorName;
  }

  private deleteFeature(id: string) {
    if (this.features.length < 1) return;

    const featureIndex = this.features.findIndex(
      (feature) => feature.getLmId() === id,
    );
    if (featureIndex === -1) return;

    const popup = this.popupStore[id];

    if (popup) {
      popup.remove();
      delete this.popupStore[id];
    }

    this.features.splice(featureIndex, 1);
    this.reloadFeatureLabels();
  }

  private enhanceLMFeatures(LMFeatures: LMFeature[]) {
    const currentlySelectedFeature =
      this.LMMap.getFeatureStateDelegate().getFeatureForState(
        StateType.SELECTED,
      );

    return LMFeatures.map((lmFeature: LMFeature) => {
      // Throws an error about the object not being extendable, so only seems to work if it's deep-cloned
      const feature = JSON.parse(JSON.stringify(lmFeature.getMapboxFeature()));

      const featureFloorId = lmFeature.getFloorId();
      const featureFloor = featureFloorId && getFloorById(featureFloorId);

      if (featureFloor) {
        feature.properties.floor_name = featureFloor.name;
        feature.properties.floor_id = undefined; // this is needed so that the feature dots appear on all floors
        feature.properties.poi_floor_id = featureFloor.id;
      }

      if (
        currentlySelectedFeature !== null &&
        currentlySelectedFeature.getId() === lmFeature.getId()
      ) {
        feature.properties.selected = "active";
      } else {
        feature.properties.selected = "inactive";
      }

      const centroid = lmFeature.getCentroid();
      if (!centroid)
        throw new Error(
          `Centroid does not exist on LMFeature for: ${lmFeature.getId()}`,
        );

      feature.geometry = {
        type: "Point",
        coordinates: centroid,
      };

      feature.properties = {
        ...feature.properties,
        preventClickPropagation: true,
      };

      // Mapbox doesn't handle null values correctly, so remove any property that is null to allow
      // pins to be displayed correctly
      for (const k in feature.properties) {
        if (feature.properties[k] === null) {
          delete feature.properties[k];
        }
      }

      return feature;
    });
  }

  private createFeatureDot(type: FeatureDotTypes) {
    let size = 0;
    let innerColour = "";
    let data: Uint8Array | Uint8ClampedArray = new Uint8Array(size * size * 4);
    const borderColour = "#fff";

    if (type === FeatureDotTypes.ACTIVE) {
      size = 40;
      innerColour = "#ff7100";
    } else {
      size = 35;
      innerColour = "#666666";
    }

    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext("2d");

    const radius = (size / 2) * 0.3;

    context!.clearRect(0, 0, size, size);

    // Draw the inner circle.
    context!.beginPath();
    context!.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
    context!.fillStyle = innerColour;
    context!.strokeStyle = borderColour;
    context!.lineWidth = 2;
    context!.shadowOffsetX = 0;
    context!.shadowOffsetY = 0;
    context!.shadowBlur = 8;
    context!.shadowColor = "rgba(0, 0, 0, 0.3)";
    context!.fill();
    context!.stroke();

    // Update this image's data with data from the canvas.
    data = context!.getImageData(0, 0, size, size).data;

    return {
      width: size,
      height: size,
      data,
    };
  }

  // Formula to calculate bearing between 2 geographical points
  // https://stackoverflow.com/a/52079217/16039554
  private calculateBearing(point1: [number, number], point2: [number, number]) {
    const y = Math.sin(point2[0] - point1[0]) * Math.cos(point2[1]);
    const x =
      Math.cos(point1[1]) * Math.sin(point2[1]) -
      Math.sin(point1[1]) *
        Math.cos(point2[1]) *
        Math.cos(point2[0] - point1[0]);

    const bearing = Math.atan2(y, x);
    const bearingInDegrees = (bearing * (180 / Math.PI) + 360) % 360;

    // get map bearing to account for map rotation
    return (
      (bearingInDegrees + 360 - (this.mapInstance?.getBearing() || 0)) % 360
    );
  }

  private getFloorDifference(id: number): {
    floorChangeAmount: number;
    floorChangeDirection: FloorChange;
  } {
    const floors = Object.values(this.floors);
    const [defaultFloorPos, featureFloorPos] = [this.defaultFloor.id, id].map(
      (floorId) => floors.findIndex((floor) => floor.id === floorId),
    );

    const difference = featureFloorPos - defaultFloorPos;
    let direction = FloorChange.NONE;

    if (difference > 0) direction = FloorChange.UP;
    else if (difference < 0) direction = FloorChange.DOWN;

    return {
      floorChangeAmount: Math.abs(difference),
      floorChangeDirection: direction,
    };
  }
}

export default ClusteredPinPlugin;
