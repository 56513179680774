import React, { FC } from "react";
import classNames from "classnames";
import { Icon, IconType } from "@livingmap/core-ui-v2";

import { UITheme } from "../types";

import styles from "./Button.module.scss";

interface Props {
  size?: "small" | "medium" | "large";
  rightIcon?: IconType;
  leftIcon?: IconType;
  disabled?: boolean;
  outlined?: boolean;
  rounded?: boolean;
  color?: "blue" | "black" | "white";
  theme?: UITheme;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  dataQA: string;
  className?: string;
  label: string;
}

const Button: FC<Props> = ({
  color = "black",
  size = "medium",
  disabled = false,
  rightIcon,
  leftIcon,
  outlined = false,
  rounded = false,
  theme = "light",
  onClick,
  label,
  dataQA,
  className,
}) => {
  return (
    <button
      disabled={disabled}
      data-qa={dataQA}
      onClick={onClick}
      className={classNames(
        styles.button,
        styles[size],
        styles[color],
        styles[theme],
        className,
        {
          [styles.outlined]: outlined,
          [styles.rounded]: rounded,
        },
      )}
    >
      {leftIcon && (
        <Icon
          dataQA="icon"
          type={leftIcon}
          className={classNames(styles.icon, styles.leftIcon)}
        />
      )}
      <span data-qa="text">{label}</span>
      {rightIcon && (
        <Icon
          dataQA="icon"
          type={rightIcon}
          className={classNames(styles.icon, styles.rightIcon)}
        />
      )}
    </button>
  );
};

export default Button;
