import LivingMap, {
  LivingMapPlugin,
  LMFeature,
  GlobalFilters,
} from "@livingmap/core-mapping";
import ClusteredPinControl from "./clustered-pin-control";
import MapGeometryHighlighter from "./geometry-visualisation/geometry-highlight";
import MapGeometryHover from "./geometry-visualisation/geometry-hover";
import MapGeometrySelector from "./geometry-visualisation/geometry-select";
import UserClickAction from "./user-interactions/user-click-actioner";
import UserMouseMoveAction from "./user-interactions/user-mousemove-actioner";
import { SourceIds, FeatureLayers } from "./types/index";
import { store } from "../../../store";

class InteractionPlugin extends LivingMapPlugin {
  private shouldSelectLocationUI: boolean | null = null;

  private clusteredPinControl: ClusteredPinControl;
  private selectedFeatureSource: mapboxgl.GeoJSONSource | null = null;

  private userClickActioner: UserClickAction;
  private mouseMoveActioner: UserMouseMoveAction;

  private geometrySelector: MapGeometrySelector;
  private geometryHighlighter: MapGeometryHighlighter;
  private geometryHoverer: MapGeometryHover;

  private handleFeatureSelect: ((feature: LMFeature) => void) | null;

  constructor(
    id: string,
    LMMap: LivingMap,
    clusteredPinPlugin: ClusteredPinControl,
  ) {
    super(id, LMMap);

    this.clusteredPinControl = clusteredPinPlugin;

    this.userClickActioner = new UserClickAction(LMMap);
    this.mouseMoveActioner = new UserMouseMoveAction(LMMap);

    this.geometrySelector = new MapGeometrySelector(LMMap);
    this.geometryHighlighter = new MapGeometryHighlighter(LMMap);
    this.geometryHoverer = new MapGeometryHover(LMMap);
    this.handleFeatureSelect = null;
  }

  /**
   * public activate enables functionality of map control
   * @public
   */
  activate(): void {
    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.on("click", this.userClickActioner.handle);
    mapInstance.on("mousemove", this.mouseMoveActioner.handle);

    if (mapInstance.getSource(SourceIds.SELECTED_FEATURE_SOURCE_ID) !== null) {
      this.selectedFeatureSource = mapInstance.getSource(
        SourceIds.SELECTED_FEATURE_SOURCE_ID,
      ) as mapboxgl.GeoJSONSource;
    }

    const filterInstance = this.LMMap.getFilterKing();
    filterInstance.updateLocalFilter(FeatureLayers.SELECTED_FEATURE_LAYER, {
      globalExclusions: [GlobalFilters.LAYERS],
    });
  }

  /**
   * public deactivate disables functionality of map control
   * @public
   */
  deactivate(): void {
    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.off("click", this.userClickActioner.handle);
    mapInstance.off("mousemove", this.mouseMoveActioner.handle);
  }

  public subscribeToFeatureSelect(callback: (feature: LMFeature) => void) {
    this.handleFeatureSelect = callback;
  }

  public selectFeature(feature: LMFeature): void {
    const {
      application: { onlineMode },
    } = store.getState();

    this.handleFeatureSelect && this.handleFeatureSelect(feature);

    // if the feature that is selected belongs to the live location subsystem, the call is passed on and halted here
    if (feature.getSource() === SourceIds.LIVE_LOCATION_SOURCE_ID) {
      this.LMMap.emit("LiveLocationSelected", feature.getId());
      return;
    }

    // when selecting a geometry, we want to ensure no geometry has an active visualisatoin state.
    this.geometryHighlighter.unhighlight(false);
    this.geometryHoverer.unhover(false);

    this.clusteredPinControl.updateFeatureLabels([feature]);

    !onlineMode && this.LMMap.easeTo(feature);
  }

  public deselectFeatures(silent = false): void {
    if (!silent) {
      this.LMMap.emit("LiveLocationSelected", null);
    }
  }

  public highlightFeature(feature: LMFeature): boolean {
    return this.geometryHighlighter.highlight(feature);
  }

  public dehighlightFeatures(): boolean {
    return this.geometryHighlighter.unhighlight();
  }

  public clearSelectedFeatureSource(): void {
    if (!this.selectedFeatureSource) return;

    this.selectedFeatureSource.setData({
      type: "FeatureCollection",
      features: [],
    });
  }

  public updateSelectedFeatureSource(feature: LMFeature): void {
    if (!this.selectedFeatureSource) return;

    // Note: we need to mutate the feature properties for some reason
    // unknown as to why.
    // TODO: perhaps add a LMFeature.copy() And an LMFeature.Builder.withGeometry().withProperties().build() ???
    const mapboxFeature = Object.assign({}, feature.getMapboxFeature());

    const centroid = feature.getCentroid();
    if (!centroid)
      throw new Error(
        `Centroid does not exist on LMFeature for: ${feature.getId()}`,
      );

    mapboxFeature.geometry = {
      type: "Point",
      coordinates: centroid,
    };

    mapboxFeature.properties = {
      ...mapboxFeature.properties,
      selected: "active",
      preventClickPropagation: true,
      pin_type: feature.getPinType(),
    };

    this.selectedFeatureSource!.setData({
      type: "FeatureCollection",
      features: [mapboxFeature],
    });
  }

  public getSelectLocationUI(): boolean | null {
    return this.shouldSelectLocationUI;
  }
}

export default InteractionPlugin;
