import classNames from "classnames";

import { Theme } from "../../../../redux/services/config";
import styles from "./AssetLabel.module.scss";

interface Props {
  dataQA: string;
  theme: Theme;
  name: string;
  floorName?: string;
  className?: string;
  onClick: () => void;
  building?: string;
}

const AssetLabel: React.FC<Props> = ({
  dataQA,
  theme,
  name,
  floorName,
  onClick,
  className,
  building,
}) => {
  return (
    <div
      data-qa={dataQA}
      onClick={onClick}
      className={classNames(
        styles.container,
        styles[theme.mode || "light"],
        styles[theme.size || "small"],
        className,
      )}
    >
      <div className={styles.content}>
        <p className={styles.name}>{name}</p>
        {(floorName || building) && (
          <p className={styles.address}>
            {floorName && floorName}
            {floorName && building && (
              <span className={styles.separator}>•</span>
            )}
            {building && building}
          </p>
        )}
      </div>
    </div>
  );
};

export default AssetLabel;
